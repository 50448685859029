import React from 'react';
import { Link, NavLink } from 'react-router-dom';
import { FaCopyright, FaFacebookF, FaTwitter, FaInstagram, FaLinkedinIn } from 'react-icons/fa';
import logo from '../assets/images/xonads Logo White Orange.png';

export default function Footer() {
  return (
    <div className="bg-customGray px-4 py-10 border-t-2">
      <div className="container mx-auto grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 text-white gap-8">
        <div className="space-y-4">
          <h1 className="font-semibold text-lg md:text-xl">About Us</h1>
          <p className="text-sm font-normal md:text-base">
            XonAds is a Growth & Tech platform committed to empowering Brands, Agencies, and Publishers with integrated MadTech (MarTech & AdTech) solutions for driving Acquisition and Growth.
          </p>
        </div>
        <div className="space-y-4">
          <h1 className="font-semibold text-lg md:text-xl">Quick Links</h1>
          <ul className="space-y-2">
            <li>
              <NavLink to="/advertisers" className="text-sm md:text-base font-normal">Advertisers</NavLink>
            </li>
            <li>
              <NavLink to="/publishers" className="text-sm md:text-base font-normal">Publishers</NavLink>
            </li>
            <li>
              <NavLink to="/contact" className="text-sm md:text-base font-normal">Contact Us</NavLink>
            </li>
            <li>
              <NavLink to="/services" className="text-sm md:text-base font-normal">Services</NavLink>
            </li>
          </ul>
        </div>
        <div className="space-y-4">
          <h1 className="font-semibold text-lg md:text-xl">Useful Links</h1>
          <ul className="space-y-2">
            <li>
              <NavLink to="/advertiserterms" className="text-sm md:text-base font-normal">Advertiser T&C</NavLink>
            </li>
            <li>
              <NavLink to="/publisherterms" className="text-sm md:text-base font-normal">Publisher T&C</NavLink>
            </li>
            <li>
              <NavLink to="/privacypolicy" className="text-sm md:text-base">Privacy & Policy</NavLink>
            </li>
          </ul>
        </div>
        <div className="flex justify-center lg:justify-end my-8 lg:my-0">
          <Link to="/">
            <img src={logo} alt='xonads' className="w-40 md:w-52" />
          </Link>
        </div>
      </div>
      <hr className="my-2 border-gray-700" />
      <div className="container mx-auto flex flex-col lg:flex-row justify-between items-center text-gray-300 space-y-4 lg:space-y-0">
        <div className="flex items-center space-x-2">
          <FaCopyright className="text-lg" />
          <p className="text-sm md:text-base">Copyright © 2024 XonAds</p>
        </div>
        <div className="flex space-x-4">
          <a href="https://www.facebook.com" target="_blank" rel="noopener noreferrer" className="rounded-full bg-white text-orange-500 p-2">
            <FaFacebookF />
          </a>
          <a href="https://www.twitter.com" target="_blank" rel="noopener noreferrer" className="rounded-full bg-white text-orange-500 p-2">
            <FaTwitter />
          </a>
          <a href="https://www.instagram.com" target="_blank" rel="noopener noreferrer" className="rounded-full bg-white text-orange-500 p-2">
            <FaInstagram />
          </a>
          <a href="https://www.linkedin.com" target="_blank" rel="noopener noreferrer" className="rounded-full bg-white text-orange-500 p-2">
            <FaLinkedinIn />
          </a>
        </div>
      </div>
    </div>
  );
}
