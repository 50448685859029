import React from 'react';
import img from '../assets/images/Insterstitial.png';
import publisher from '../assets/images/publisher.png';
import p1 from '../assets/images/p1.png';
import p2 from '../assets/images/p2.png';
import p3 from '../assets/images/p3.png';
import { NavLink } from 'react-router-dom';

function Publishers() {
  const pub = [
    {
      id: 1,
      pic: <i className="fa-solid fa-chalkboard-user "></i>,
      name: "Transparent Working Process",
      description: "Our communication with you is transparent and aligned, ensuring prompt feedback, professional staff, and a willingness to negotiate any potential issues to your advantage.",
    },
    {
      id: 2,
      pic: <i className="fa-solid fa-money-bill-trend-up"></i>,
      name: "Diverse Offers",
      description: "Our offer selection remains fresh and dynamic, thanks to our proactive team continuously scouting the hottest moneymakers in the market. Our direct CPA, CPL, and CPI offers are optimized to receive your traffic effectively.",
    },
    {
      id: 3,
      pic: <i className="fa-solid fa-user"></i>,
      name: "Ethics Is The King",
      description: "We support our partners and strive for mutual understanding. Honesty is our guiding principle, and we stand firmly by it."
    },
    {
      id: 4,
      pic: <i className="fa-solid fa-clock"></i>,
      name: "Payments Right On Time",
      description: "We value your time as much as you do. Our efficient payout system ensures timely payments, keeping both parties satisfied."
    }
  ];

  return (
    <>
      <div className='bg-customGray px-4 py-2'>
        <div className='lg:my-10 sm:my-4 my-1 lg:py-6  py-2'>
          <h1 className='text-white text-5xl md:text-[120px] lg:text-[140px] text-center font-bold uppercase mb-2'>
            Publishers
          </h1>
          <p className='text-sm md:text-base font-gotham text-center text-white leading-[1.537em] px-2 sm:px-10 lg:px-52'>
            "Constantly expanding our pool of offers and ensuring timely payouts, Äppska welcomes new partnerships with ad networks, media buying teams, developers with proprietary apps ready to provide traffic, and more. We take pride in our long-term and fruitful cooperation with the majority of our publishing partners."
          </p>
          <NavLink to={"/contact"}><div className='flex justify-center my-8 py-4'>
            <button className="text-white border-white border-[1px] py-2 md:py-4 px-4 md:px-8 rounded-3xl bg-transparent font-semibold bg-gradient-to-r from-orange-500 to-orange-800 hover:border-none uppercase">
              Get in touch
            </button>
          </div>
          </NavLink>
        </div>

        <div className='md:my-5 my-2 md:py-6 py-2'>
          <h1 className='text-center text-white font-semibold md:text-5xl text-3xl uppercase'>Here is Why it Works <br />so Well</h1>
          <div className='grid grid-cols-1 md:grid-cols-4 gap-4 lg:gap-16  sm:px-6 px-2 md:px-10 py-12 text-center'>
            {pub.map((item, index) => (
              <div key={index}>
                <div className='text-orange-600 text-6xl'>{item.pic}</div>
                <h1 className='text-white lg:text-2xl text-xl font-semibold py-3'>{item.name}</h1>
                <p className='text-white text-base'>{item.description}</p>
              </div>
            ))}
          </div>
        </div>

        <div className='pb-10'>
          <h1 className='text-center font-semibold md:text-5xl text-3xl uppercase text-white md:py-6 py-4'>Ad Units</h1>
          <div className='flex justify-center'>
            {/*
              Adjust the width of the image container and the image itself for responsiveness.
              You may also consider using Tailwind's responsive image classes for better control.
            */}
            <div className='max-w-[90%] md:max-w-[800px]'>
              <img src={img} alt='img' className='w-full' />
            </div>
          </div>
        </div>

        <div className='bg-custom-gradient border-[1px] rounded-lg p-4 my-6 mx-4 md:mx-32 shadow-md shadow-slate-300 mb-20'>
          <div className='grid grid-cols-1 sm:grid-cols-2'>
            <div>
              <img src={publisher} alt='benefits' className=' sm:w-[300px] w-[200px]' />
            </div>
            <div className='my-4'>
              <div className='flex items-center my-4'>
                <img src={p1} alt='benefits' className='h-12 w-12 mr-2' />
                <p className='text-white text-base'>100% Guarantee Payments</p>
              </div>
              <div className='flex items-center my-4'>
                <img src={p2} alt='benefits' className='h-12 w-12 mr-2' />
                <p className='text-white text-base'>Flexible Payments</p>
              </div>
              <div className='flex items-center my-4'>
                <img src={p3} alt='benefits' className='h-12 w-12 mr-2' />
                <p className='text-white text-base'>Consistent Process</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Publishers;
