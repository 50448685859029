import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { FaBars, FaTimes } from 'react-icons/fa';
import logo from '../assets/images/xonads Logo White Orange.png'

export default function Header() {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <nav className="bg-customGray p-4">
      <div className="container mx-auto flex justify-between items-center">
        <div>
          <Link to="/">
            <img src={logo} alt='xonads' className='md:w-[200px] sm:w-[150px] w-[120px] '/>
          </Link>
        </div>
        <div className="md:hidden flex items-center">
          <button onClick={toggleMenu} className="text-white focus:outline-none">
            {isOpen ? <FaTimes /> : <FaBars />}
          </button>
        </div>
        <div className={`md:flex space-x-4 ${isOpen ? 'flex' : 'hidden'} flex-col md:flex-row md:space-y-0 space-y-2 absolute md:static top-16 left-0 w-full md:w-auto bg-customGray md:bg-transparent p-4 md:p-0 gap-8`}>
          <Link to="/advertisers" className="text-white md:text-lg font-medium hover:text-orange-500 transition">Advertiser</Link>
          <Link to="/publishers" className="text-white md:text-lg font-medium hover:text-orange-500 transition">Publishers</Link>
          <Link to="/services" className="text-white md:text-lg font-medium hover:text-orange-500 transition">Services</Link>
          <Link to="/contact" className="text-white md:text-lg font-medium hover:text-orange-500 transition">Contact Us</Link>
        </div>
      </div>
    </nav>
  );
}
