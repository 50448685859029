import React, { useState } from 'react';
import { FaEnvelopeOpenText } from 'react-icons/fa';

export default function ContactUs() {
  // State variables to store form data
  const [formData, setFormData] = useState({
    firstname: '',
    lastname: '',
    email: '',
    subject: '',
    message: ''
  });

  // Handle input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    // Here you can implement form submission logic, e.g., send data to backend
    console.log(formData); // For demonstration, logging form data
    // Clear form after submission
    setFormData({
      firstname: '',
      lastname: '',
      email: '',
      subject: '',
      message: ''
    });
  };

  return (
    <>
      <div className='bg-customGray px-4 py-2'>
        <div className='grid sm:grid-cols-2 grid-cols-1 md:py-16 py-10 px-2 text-white'>
          <div>
            <p className='font-semibold lg:text-[30px] md:text-[20px] text-[18px] md:pl-[20px] pl-[8px] dark:text-white'>Where You Can Find Us</p>
            <p className='lg:text-[18px] sm:text-[16px] text-[14px] md:py-2 py-1 lg:px-6 md:px-4 px-2 text-start text-white'>We have offices from coast-to-coast and a global customer reach. Here is where you can find us:</p>
            <div className='md:pl-[24px] pl-[10px]'>
              <div className='flex sm:pt-4 pt-2 pb-1'>
                <FaEnvelopeOpenText className='sm:text-[25px] text-[20px] my-2' />
                <h1 className='lg:text-[25px] sm:text-[20px] text-[18px] font-semibold underline md:px-6 px-4'>Email</h1>
              </div>
              <div className='lg:text-[20px] md:text-[18px] sm:text-[16px] text-[14px] md:pl-12 pl-6px text-white'>support@xonads.com</div>
            </div>
          </div>
          <div className='contact-form rounded-md shadow-lg shadow-gray-600 sm:my-0 my-8'>
            <form onSubmit={handleSubmit}>
              <h1 className='lg:text-[25px] text-[20px] font-bold md:px-4 px-2 pt-2'>Let’s Talk!</h1>
              <p className='lg:text-[18px] md:text-[16px] md:px-4 px-2 pb-3'>Fill out the form below to contact our team.</p>
              <div className='flex'>
                <input
                  type='text'
                  name='firstname'
                  placeholder='Firstname'
                  autoComplete='off'
                  required
                  value={formData.firstname}
                  onChange={handleInputChange}
                  className='border-[1px] bg-customBlack rounded-md w-[50%] mx-2 my-2 py-2 placeholder-white px-2'
                />
                <input
                  type='text'
                  name='lastname'
                  placeholder='Lastname'
                  autoComplete='off'
                  required
                  value={formData.lastname}
                  onChange={handleInputChange}
                  className='border-[1px] bg-customBlack rounded-md w-[37%] mx-2 my-2 py-2 placeholder-white px-2'
                />
              </div>
              <input
                type='email'
                name='email'
                placeholder='Email Address'
                autoComplete='off'
                required
                value={formData.email}
                onChange={handleInputChange}
                className='border-[1px] border-gray-500 bg-customBlack rounded-md w-[90%] mx-2 my-2 py-2 placeholder-white px-2'
              />
              <input
                type='text'
                name='subject'
                placeholder='Subject'
                autoComplete='off'
                required
                value={formData.subject}
                onChange={handleInputChange}
                className='border-[1px] border-gray-500 bg-customBlack rounded-md w-[90%] mx-2 my-2 py-2 placeholder-white px-2'
              />
              <textarea
                name='message'
                cols='30'
                rows='6'
                autoComplete='off'
                placeholder='Message'
                required
                value={formData.message}
                onChange={handleInputChange}
                className='border-[1px] border-gray-500 bg-customBlack rounded-md w-[90%] mx-2 my-2 py-2 placeholder-white px-2'
              />
              <div className='flex py-4'>
                <button
                  type='submit'
                  className='bg-orange-500 px-2 py-2 text-white rounded-md mx-auto font-semibold w-[20%] uppercase'
                >
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}
