import React from 'react'

export default function PublishersTerm() {
  return (
   <>
    <div className='bg-customGray md:px-4 sm:px-2 px-1 py-2'>
     <div className='text-center text-white uppercase py-4 font-semibold md:text-2xl sm:text-xl text-lg leading-9 md:leading-[0px] '>
        <h1>Publisher Terms & Conditions</h1>
     </div>
     <div className='py-4 text-white md:px-4 sm:px-2 px-1'>
     <p className='text-base leading-7'>This Publisher Terms & Conditions (the “Agreement”) is made and entered into by and between XonAds, 5001 Beach Road #08-11, Golden Mile Complex, Singapore – 199588 (“XonAds”), and you (“Publisher” or “her”/”she”), the party submitting an application to become an XonAds Publisher. Each Publisher Program offer (an “Offer”) may be for any offering by XonAds or a third party (each such third party a “Client”) and may link to a specific website for that particular Offer (“Mobile App”). Furthermore, each Offer may have additional terms and conditions on pages within the Publisher Program that are incorporated as part of this Agreement. By submitting an application or participating in an Offer, you expressly consent to all the terms and conditions of this Agreement.</p>
     </div>
     <div className='py-4 text-white md:px-4 sm:px-2 px-1'>
        <h1 className='text-base leading-7'>1. Enrollment in the Publisher Program</h1>
        <ul>
        <li className='text-base leading-7'>You must submit a Publisher Program application from our website. You must accurately complete the application to become a Publisher (and provide us with future updates) and not use any aliases or other means to mask your true identity or contact information. After we review your application, we will notify you of your acceptance or rejection to the Publisher Program, generally within five (5) business days. We may accept or reject your application at our sole discretion for any reason.</li>
        <li className='text-base leading-7'>The Publisher must accurately complete the application to become a Publisher (and provide us with future updates) and must not use any aliases or other means to mask Publisher’s true identity or contact information.</li>
        <li className='text-base leading-7'>XonAds may accept or reject Publisher’s application at its sole discretion for any reason, generally within five (5) business days.</li>
        </ul>
    </div>
    <div className='py-4 text-white md:px-4 sm:px-2 px-1'>
    <h1 className='text-base leading-7'>2. Obligations of the Parties</h1>
    <p className='py-2 '>a. Subject to our acceptance of you as a Publisher and your continued compliance with the terms and conditions of this Agreement, XonAds agrees as follows:</p>
    <ul className='list-disc'>
        <li className='text-base leading-7 py-2'>XonAds will make available to Publisher via the Publisher Program graphic and textual links to the Mobile App and/or other creative materials (collectively, the “Links”) which Publisher may display on websites and apps owned or controlled by him/her, in emails sent by Publisher and clearly identified as coming from him/her, and in online advertisements (collectively, “Media”). The Links will serve to identify Publisher as a member of XonAds’s Publisher Program and will establish a link from his/her Media to the Mobile App.</li>
        <li className='text-base leading-7 py-2'>XonAds will pay Publisher for each Qualified Action (the “Commission”) an amount as defined in the respective Offer’s description. A “Qualified Action” means an action as defined in the Offer’s description performed by an individual person who (i) accesses the Mobile App via the Link, where the Link is the last link to the Mobile App, (ii) is not a computer-generated user, such as a robot, spider, computer script, or other automated, artificial, or fraudulent method to appear like an individual, real live person, (iii) is not using pre-populated fields, (iv) completes all of the information required for such action within the time period allowed by XonAds, and (v) is not later determined by XonAds to be fraudulent, incomplete, unqualified, or a duplicate.</li>
        <li className='text-base leading-7 py-2'>XonAds will pay Publisher any Commissions earned monthly, provided that his/her due account balance is greater than 100 USD. Accounts with a balance of less than 100 USD will roll over to the next month, and will continue to roll over monthly until 100 USD is reached. Since XonAds will endeavor to pay out Commissions to the Publisher as early as possible, this cannot be done unconditionally. XonAds therefore reserves the right to charge back to Publisher’s account any previously paid Qualified Actions that are later determined to have not met the requirements to be a Qualified Action. In case Publisher’s later account balance is lower than the due charge back amount, he/she is obliged to transfer the due amount back to XonAds upon request within no more than 7 business days. XonAds reserves the right to claim charge backs for up to 3 months after the respective Qualified Action has been performed by the user. XonAds also reserves the right to reclaim a payment at a later date within the statutory limitation periods if XonAds can show that the payment to the Publisher was not based on a payment claim due to a Qualified Action.</li>
        <li className='text-base leading-7 py-2'>In case XonAds is obliged to pay back monies already collected to Clients, banks, or payment providers, such monies are deductible in the month they are paid back. The same applies in respect of monies paid back to Clients, banks, or payment providers in the event that XonAds agrees to do so on a bona fide basis to settle a dispute, unless such settlement is not reasonable, and the Publisher contradicts in writing within 10 business days upon being informed. Circumstances which oblige XonAds to pay back monies to Clients include alleged abuse or other instances of fraud for which XonAds is not responsible, as well as refunds. Circumstances which oblige XonAds to pay back monies to payment providers or banks include credit card or debit card fraud committed by Publishers or their agents, and “Chargebacks”. Chargebacks mean any credit or debit card transactions that are returned for reimbursement of the cardholder’s account for any reason.</li>
    </ul>
    <p className='py-2 '>b. Publisher also agrees to:</p>
    <ul className='list-disc'>
        <li className='text-base leading-7 py-2'>Have sole responsibility for the development, operation, and maintenance of, and all content on or linked to, Publisher’s Media.</li>
        <li className='text-base leading-7 py-2'>Ensure that all materials posted on Publisher’s Media or otherwise used in connection with the Publisher Program (i) are not illegal, (ii) do not infringe upon the intellectual property or personal rights of any third party, and (iii) do not contain or link to any material which is harmful, threatening, defamatory, obscene, sexually explicit, harassing, promotes violence, promotes discrimination (whether based on sex, religion, race, ethnicity, nationality, disability, or age), promotes illegal activities (such as gambling where applicable), contains profanity, or otherwise contains materials that XonAds informs Publisher that it considers objectionable (collectively, “Objectionable Content”).</li>
        <li className='text-base leading-7 py-2'>Accept that XonAds neither endorses nor approves of actions performed or content made available by the Publisher.</li>
        <li className='text-base leading-7 py-2'>Hold XonAds harmless of any third party claims alleging the Publisher Media contains illegal content.</li>
        <li className='text-base leading-7 py-2'>Not make any representations, warranties, or other statements concerning XonAds or Client or any of their respective products or services, except as expressly authorized herein.</li>
        <li className='text-base leading-7 py-2'>Always prominently post and make available to end-users, including prior to the collection of any personally identifiable information, a privacy policy in compliance with all applicable laws that clearly and thoroughly discloses all information collection, use and sharing practices, including providing for the collection of such personally identifiable information in connection with the Publisher Program and the provision of such personally identifiable information to XonAds and Clients for use as intended by XonAds and Clients, further detailed in Sect. 16 of the Agreement.</li>
        <li className='text-base leading-7 py-2'>Always prominently post and make available to end-users any terms and conditions in connection with the Offer set forth by XonAds or Client, or as required by applicable laws regarding such Offers.</li>
        <li className='text-base leading-7 py-2'>
        Make sure not to place XonAds ads on any online auction platform (i.e., eBay, Amazon, etc).
        </li>
    </ul>
    <p className='py-2 '>c. The following additional program-specific terms shall apply to any promotional programs set forth below:</p>
    <p className='text-base leading-7 py-2'>1. Email Campaigns. For all email campaigns, Publisher must – if available – download the “Suppression List” from the Offers section of XonAds. Publisher shall filter its email list by removing any entries appearing on the Suppression List and will only send emails to the remaining addresses on its email list. Publisher’s emails containing the Links may not include any content other than the Links, except as required by applicable law.</p>
    <ul className='list-disc'>
        <li className='text-base leading-7 py-2'>Advertising Campaigns. No Links can appear to be associated with or be positioned on chat rooms or bulletin boards unless otherwise agreed by XonAds in writing. Any pop-ups/unders used for the Publisher Program shall be clearly identified as Publisher served in the title bar of the window, and any client-side ad serving software used by Publisher shall only have been installed on an end-user’s computer if the function of the software is clearly disclosed to end-users prior to installation, the installation is pursuant to an affirmatively accepted end-user license agreement, and the software can be easily removed according to generally accepted methods.</li>
        <li className='text-base leading-7 py-2'>Unless otherwise agreed upon, Publisher may not use any self-generated creative assets that have not explicitly been approved by XonAds. Self-generated creative assets that fully comply with the advertiser’s marketing message and brand identity, and are therefore only adjusted variations of the creative material provided in the offer description, will generally be approved.</li>
    </ul>
    </div>
    <div className='py-4 text-white md:px-4 sm:px-2 px-1'>
    <h1 className='text-base leading-7'>3. Confidentiality, Non-Circumvention</h1>
    <p className='text-base leading-7'>Except as otherwise provided in this Agreement or with the consent of XonAds, Publisher agrees that all information, including, without limitation, the terms of this Agreement, business and financial information, customer and vendor lists, and pricing and sales information, concerning XonAds or any of XonAds’s clients and/or partners provided by or on behalf of any of them shall remain strictly confidential and secret and shall not be utilized, directly or indirectly, by Publisher for any purpose other than his/her participation in the Publisher Program.</p>
    <p className='text-base leading-7'>The foregoing restriction does not apply to information that has been developed independently by the receiving party without access to the other party’s Confidential Information, or has been rightfully received from a third party authorized to make such disclosure, or has been approved for release in writing by the disclosing party, or has become publicly known through no breach of this Section 3 by the receiving party, or is required to be disclosed by a competent legal or governmental authority, provided that the receiving party gives the disclosing party prompt written notice of such requirement prior to disclosure and assists in obtaining an order to protect the information from public disclosure.</p>
    </div>
    <div className='py-4 text-white md:px-4 sm:px-2 px-1 pb-10'>
    <h1 className='text-base leading-7'>3. Limited License & Intellectual Property</h1>
    <p className='text-base leading-7'>XonAds grants Publisher a nonexclusive, nontransferable, revocable right to use the Links and to access XonAds’s website through the Links solely in accordance with the terms of this Agreement, for the sole purpose of identifying Publisher’s Media as a participant in the Publisher Program and assisting in increasing sales through the Mobile App. XonAds does not grant to the Publisher any license, express or implied, to the intellectual or industrial property of XonAds or its licensors, except for a limited right of use according to the terms and for the duration of the Agreement.</p>
    <p className='text-base leading-7'>Publisher is only entitled to use the Links to the extent that they are a member in good standing of the Publisher Program. XonAds may revoke Publisher’s license at any time by giving written notice. Publisher agrees that they will use any data (including any usage data and compilations thereof), information, or software provided by XonAds only for the purpose of providing and optimizing Links for XonAds on their Media according to the Agreement.</p>
    <p className='text-base leading-7'>By registering, Publisher grants Globale Media the right to name his/her as a reference for Globale Media’s services. This includes the right to use the Publisher’s logo on Globale Media’s websites and show advertising best practices to other Publishers of Globale Media. The Publisher may revoke this right at any time, in writing, for any future use. Publisher agrees that Globale Media may use any suggestion, comment or recommendation she chooses to provide to Globale Media without compensation.</p>
    <p className='text-base leading-7'>All rights not expressly granted in this Agreement are reserved by XonAds. XonAds will retain all rights, title, and interests in and to the Advertising Platform (except for any licensed content and third-party Advertisements included therein), including all data (such as any usage data and compilations thereof), information, and software related thereto. The Publisher acknowledges that the software, information, content, and data related to the Advertising Platform (such as any usage data or compilations thereof) are protected for XonAds under copyright and similar rights and may contain trade secrets or other intellectual or industrial property owned or licensed by XonAds.</p>
    </div>
    </div>
   </>
  )
}
