import './App.css';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from './pages/Home';
import Header from './components/Header';
import Footer from './components/Footer';
import Advertisers from './pages/Advertisers';
import Publishers from './pages/Publishers';
import  Services from './pages/Services';
import ContactUs from './pages/ContactUs';
import AdvertiserTerms from './pages/AdvertiserTerms';
import PublishersTerm from './pages/PublishersTerm';
import PrivacyPolicy from './pages/PrivacyPolicy';

function App() {
  return (
    <>
    <BrowserRouter>
    <Header/>
    <Routes>
    <Route path='/' element={<Home/>}/>
    <Route path='/advertisers' element={<Advertisers/>}/>
    <Route path='/publishers' element={<Publishers/>}/>
    <Route path='/services' element={<Services/>}/>
    <Route path='/contact' element={<ContactUs/>}/>
    <Route path='/advertiserterms' element={<AdvertiserTerms/>}/>
    <Route path='/publisherterms' element={<PublishersTerm/>}/>
    <Route path='/privacypolicy' element={<PrivacyPolicy/>}/>
    </Routes>
    <Footer/>
    </BrowserRouter>
    </>
  );
}

export default App;
