import React from 'react';
import advertiser from '../assets/images/mark.png';
import benefit from '../assets/images/advertiserbenefit.png';
import benefits from '../assets/images/benefits.png';
import recall from '../assets/images/recall.png';
import targeting from '../assets/images/targeting.png';
import rates from '../assets/images/rate.png';
import reduction from '../assets/images/reduction.png';
import oneimg from '../assets/images/1stimg.png';
import twoimg from '../assets/images/2ndimg.png';
import threeimg from '../assets/images/3rdimg.png';
import powerful from '../assets/images/powerful.png';
import friendly from '../assets/images/friendly.jpg';
import distribution from '../assets/images/global.jpg';
import managment from '../assets/images/managment.jpg';
import phone from '../assets/images/phone.png';
import one from '../assets/images/one.jpg';
import two from '../assets/images/two.jpg';
import three from '../assets/images/three.jpg';
import four from '../assets/images/four.jpg';
import five from '../assets/images/five.jpg';
import six from '../assets/images/six.jpg';
import seven from '../assets/images/seven.jpg';
import eight from '../assets/images/eight.jpg';

import { NavLink } from 'react-router-dom';

function Advertiser() {
  return (
    <>
      <div className="bg-customGray px-4 py-12">
        <div className="container mx-auto items-center grid grid-cols-1 md:grid-cols-2">
          <div className='text-white'>
            <h1 className='text-3xl md:text-4xl uppercase font-bold mb-1'>CONNECT WITH YOUR TARGET </h1>
            <h2 className='text-xl sm:text-2xl md:text-3xl bg-gradient-to-r from-orange-500 to-orange-800 bg-clip-text text-transparent uppercase'>AUDIENCE THROUGH OUR RELIABLE NETWORK</h2>
            <p className='text-lg md:text-xl py-6'>XonAds provides direct access to a diverse array of partners via a trusted business model</p>
            <NavLink to={"/contact"}>
              <button className="border-[1px] border-white bg-transparent py-2 md:py-4 rounded-lg bg-gradient-to-r from-orange-500 to-orange-800 hover:border-none transition w-[60%] md:w-[30%] font-semibold">
                Contact Us
              </button>
            </NavLink>
          </div>
          <div className='py-4'>
            <img src={advertiser} alt='advertiser img' className='mx-auto w-[500px]' />
          </div>
        </div>
        <div className='md:my-20 my-10'>
          <div>
            <h1 className='uppercase text-2xl md:text-3xl font-bold text-center text-white lg:relative'>
              Deploy your ads across exclusive OEM & In-app SDK placements!
              <span className='lg:absolute bottom-0 left-1/2 transform -translate-x-1/2 md:w-48 md:h-1 bg-white top-10'></span>
            </h1>
            <p className='text-white text-center  px-2 sm:px-4 md:px-16 text-base md:text-lg py-4'>The XonAds self-advertising platform empowers you to manage your own ads, oversee ad sales, and optimize campaign effectiveness. Reduce costs and maximize ROI with our streamlined solution!</p>
          </div>
          <div className='bg-custom-gradient border-[1px] rounded-lg p-4 my-6 mx-4 md:mx-32 shadow-md shadow-slate-300'>
            <h1 className='text-center text-xl md:text-2xl font-semibold text-white'>Xonads self-serve advertising platform offers the following benefits:</h1>
            <div className='grid grid-cols-1 md:grid-cols-2'>
              <div>
                <img src={benefit} alt='benefits' className='md:w-[250px] w-[200px] mx-auto' />
              </div>
              <div className='my-4'>
                <div className='flex items-center md:my-2 my-6'>
                  <img src={oneimg} alt='benefits' className='h-12 w-12 mr-2' />
                  <p className='text-white'>Advertisers get 24/7 access to the self-serve platform</p>
                </div>
                <div className='flex items-center md:my-2 my-6'>
                  <img src={twoimg} alt='benefits' className='h-12 w-12 mr-2' />
                  <p className='text-white'>Platform enables advertisers to save time and money</p>
                </div>
                <div className='flex items-center md:my-2 my-6'>
                  <img src={threeimg} alt='benefits' className='h-12 w-12 mr-2' />
                  <p className='text-white'>The platform can be a good testing ground for your later projects</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className='my-20'>
            <h1 className='uppercase text-2xl md:text-3xl font-bold text-center text-white lg:relative'>
              Remarketing
              <span className='lg:absolute bottom-0 left-1/2 transform -translate-x-1/2 w-28 h-1 bg-white top-10'></span>
            </h1>
            <p className='text-white text-center px-4 md:px-16 text-base md:text-lg py-4'>"The Xonads platform leverages AI-driven algorithms and advanced app recommendation engines to effectively target users, maximizing ROI for app marketers</p>
          </div>
          <div className='bg-gradient-to-r from-orange-500 to-orange-800 border-[1px] rounded-lg p-4 my-4 mx-4 md:mx-32 shadow-md shadow-slate-300'>
            <div className='grid grid-cols-1 md:grid-cols-2'>
              <div>
                <img src={benefits} alt='benefits' className='md:w-[300px] w-[200px] mx-auto' />
              </div>
              <div className='my-4'>
                <div className='flex items-center md:my-2 my-6'>
                  <img src={recall} alt='benefits' className='h-12 w-12 mr-2' />
                  <p className='text-white'>Improved brand recall</p>
                </div>
                <div className='flex items-center md:my-2 my-6'>
                  <img src={rates} alt='benefits' className='h-12 w-12 mr-2' />
                  <p className='text-white'>Higher conversion rates</p>
                </div>
                <div className='flex items-center md:my-2 my-6'>
                  <img src={targeting} alt='benefits' className='h-12 w-12 mr-2' />
                  <p className='text-white'>Refined targeting</p>
                </div>
                <div className='flex items-center md:my-2 my-6'>
                  <img src={reduction} alt='benefits' className='h-12 w-12 mr-2' />
                  <p className='text-white'>Loss reduction</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='my-20'>
          <div>
            <h1 className='uppercase text-2xl md:text-3xl font-bold text-center text-white lg:relative'>
              Powerful reporting and APIs
              <span className='lg:absolute bottom-0 left-1/2 transform -translate-x-1/2 w-32 h-1 bg-white top-10'></span>
            </h1>
            <p className='text-white text-center px-4 md:px-16 text-base md:text-lg py-4'>Utilize our adaptable reporting system within our platform or seamlessly import data through our APIs into your solutions. We simplify the process of accessing comprehensive data and insights swiftly and transparently</p>
          </div>
          <div className='bg-custom-gradient border-[1px] rounded-lg p-4 my-6 mx-4 md:mx-32 shadow-md shadow-slate-300'>
            <div className='grid grid-cols-1 md:grid-cols-2'>
              <div>
                <img src={powerful} alt='benefits' className='md:w-[300px] w-[200px] mx-auto' />
              </div>
              <div className='my-4'>
                <div className='flex items-center md:my-2 my-6'>
                  <img src={distribution} alt='benefits' className='h-12 w-12 mr-2' />
                  <p className='text-white'>Stand out with worldwide app distribution</p>
                </div>
                <div className='flex items-center md:my-2 my-6'>
                  <img src={managment} alt='benefits' className='h-12 w-12 mr-2' />
                  <p className='text-white'>Highly experienced account management team</p>
                </div>
                <div className='flex items-center md:my-2 my-6'>
                  <img src={friendly} alt='benefits' className='h-12 w-12 mr-2' />
                  <p className='text-white'>"Simplified, user-centric platform"</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='grid grid-cols-1 md:grid-cols-2 mx-4 md:mx-20 my-28'>
          <div className='text-white'>
            <h1 className='font-semibold text-xl md:text-2xl relative'>
              Fraud Free Ads
              <span className='absolute bottom-0 left-0 w-20 h-1 bg-white top-10'></span>
            </h1>
            <p className='text-base md:text-lg my-10'>
              "At Globale Media, we employ a proprietary traffic scoring platform that assesses over 100 parameters and patterns to detect fraud with exceptional 95% efficiency. Integrated with top platforms like Appsflyer, Singular, Branch, and Kochava, we ensure fraud-free mobile advertising."
            </p>
            <p className='text-base md:text-lg my-8'>We have several in-house great instruments, such as:</p>
          </div>
          <div>
            <img src={phone} alt='phone img' className='mx-auto w-60 md:w-80 h-auto' />
          </div>
        </div>
        <div className='bg-gradient-to-r from-orange-500 to-orange-800 border-[1px] rounded-lg p-4 mx-4 md:mx-32 shadow-md shadow-slate-300 grid grid-cols-1 md:grid-cols-2 my-24'>
          <div className='text-white'>
            <div className='flex items-center md:my-2 my-6'>
              <img src={one} alt='benefits' className='h-12 w-12 mr-2' />
              <p className='text-white font-medium'>Minimal click session lifespan</p>
            </div>
            <div className='flex items-center md:my-2 my-6'>
              <img src={two} alt='benefits' className='h-12 w-12 mr-2' />
              <p className='text-white font-medium'>Reject not unique IP</p>
            </div>
            <div className='flex items-center md:my-2 my-6'>
              <img src={three} alt='benefits' className='h-12 w-12 mr-2' />
              <p className='text-white font-medium'>Allowed IP</p>
            </div>
            <div className='flex items-center md:my-2 my-6'>
              <img src={four} alt='benefits' className='h-12 w-12 mr-2' />
              <p className='text-white font-medium'>Secure postback code</p>
            </div>
          </div>
          <div className='text-white'>
            <div className='flex items-center md:my-2 my-6'>
              <img src={five} alt='benefits' className='h-12 w-12 mr-2' />
              <p className='text-white font-medium'>Allowed sub accounts values</p>
            </div>
            <div className='flex items-center md:my-2 my-6'>
              <img src={six} alt='benefits' className='h-12 w-12 mr-2' />
              <p className='text-white font-medium'>Click-level Anti-fraud</p>
            </div>
            <div className='flex items-center md:my-2 my-6'>
              <img src={seven} alt='benefits' className='h-12 w-12 mr-2' />
              <p className='text-white font-medium'>CR and click level automation tools</p>
            </div>
            <div className='flex items-center md:my-2 my-6'>
              <img src={eight} alt='benefits' className='h-12 w-12 mr-2' />
              <p className='text-white font-medium'>“Time to action” statistics slice</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Advertiser;
