import React from 'react';
import img1 from "../assets/images/affiliate3.png";
import samsung from "../assets/images/Samsung.png";
import mi from "../assets/images/mi.png";
import vivo from "../assets/images/Vivo.png";
import affiliate from "../assets/images/affiliate4.png";
import oppo from "../assets/images/oppo.png";
import appsflyer from "../assets/images/appflyer.png";
import huawei from "../assets/images/huawei.png";
import rhaekc from "../assets/images/rhaekc.png";
import vk from '../assets/images/vk.png';
import query from '../assets/images/query.png';
import { useTypewriter, Cursor } from 'react-simple-typewriter';
import { NavLink } from 'react-router-dom';

function Home() {
  const [typeEffect] = useTypewriter({
    words: ["ADVERTISING", "ADNETWORK", "CPA NETWORK", "AGENCY"],
    loop: {},
    typeSpeed: 80,
    deleteSpeed: 180,
  });

  return (
    <div className="bg-customGray px-4 py-2">
      <div className="container mx-auto grid grid-cols-1 md:grid-cols-2 gap-4 items-center py-16">
        <div className="text-white space-y-4">
          <button className='border-1px bg-gray-700 rounded-md px-2 py-2 font-medium font-serif'> 👋 Hi! We are</button>
          <h1 className="text-3xl md:text-5xl font-bold uppercase">Elevating Brands</h1>
          <h2 className="text-2xl md:text-4xl uppercase bg-gradient-to-r from-orange-500 to-orange-800 bg-clip-text text-transparent font-medium">With Sales Driven</h2>
          <h1 className="text-3xl md:text-5xl uppercase font-bold">{typeEffect} <Cursor cursorStyle='|' /></h1>
         <div>
         <NavLink to={"/services"}>
            <button className="border-[1px] border-white bg-transparent px-6 py-2 rounded-lg hover:bg-gradient-to-r from-orange-500 to-orange-800 hover:border-none transition">
              Our Solutions
            </button>
            </NavLink>
          </div>
          
        </div>
        <div className="flex justify-center md:justify-end my-2">
          <img src={img1} alt="affiliate" className=" w-72 lg:w-[800px]" />
        </div>
      </div>
      {/* Advertising list section */}
      <div className='relative'>
  <hr className='text-white h-[10px] lg:my-36 my-24' />
  <div className='rounded-3xl border-[1px] bg-gradient-to-r from-orange-800 to-orange-500 bg-opacity-20 py-4 px-4  absolute top-[-110px]  md:top-[-110px] left-[60px] sm:left-[110px] lg:left-[340px]  '>
    <div className='grid md:grid-cols-3 grid-cols-1 gap-6'>
      <div className='bg-white p-1  uppercase text-center'>
        <h1 className='font-bold text-2xl md:text-5xl text-customGray'>200</h1>
        <h2 className='bg-gradient-to-r from-orange-500 to-orange-800 bg-clip-text text-transparent text-lg sm:text-xl  font-medium'>DIRECT</h2>
        <h3 className='bg-gradient-to-r from-orange-500 to-orange-800 bg-clip-text text-transparent text-lg sm:text-xl  font-medium'>ADVERTISERS</h3>
      </div>
      <div className='bg-white p-1 uppercase text-center'>
        <h1 className='font-bold text-2xl md:text-5xl text-customGray'>500</h1>
        <h2 className='bg-gradient-to-r from-orange-500 to-orange-800 bg-clip-text text-transparent  text-lg sm:text-xl  font-medium'>Active</h2>
        <h3 className='bg-gradient-to-r from-orange-500 to-orange-800 bg-clip-text text-transparent text-lg sm:text-xl  font-medium'>Publishers</h3>
      </div>
      <div className='bg-white p-1 uppercase text-center'>
        <h1 className='font-bold text-2xl md:text-5xl text-customGray'>1B+</h1>
        <h1 className='bg-gradient-to-r from-orange-500 to-orange-800 bg-clip-text text-transparent text-lg sm:text-xl font-medium'>Monthly user</h1>
      </div>
    </div>
  </div>
</div>




      {/* Partners Section */}
      <div className='container mx-auto items-center sm:pt-0 pt-72 md:my-18'>
        <div className='flex justify-center mt-8'>
          <button className='border-1px bg-gray-700 rounded-md px-2 py-2  mt-8 font-medium font-serif text-white'> 🤝 Our friends and</button>
        </div>
        <div className='container mx-auto items-center md:my-4'>
          <div className='flex justify-center md:my-4 my-2'>
            <h1 className='text-white font-bold text-3xl md:text-5xl text-center'>Official Partners</h1>
          </div>
          <div className='grid grid-cols-2 md:grid-cols-4 gap-4 md:py-12 py-6 md:mx-4 mx-2'>
            <div className='partner-box flex justify-center bg-customBlack shadow-md shadow-slate-400'>
              <img src={appsflyer} alt='appsflyer' className='md:w-[300px] w-[200px] h-[200px]' />
            </div>
            <div className='partner-box flex justify-center bg-customBlack shadow-md shadow-slate-400'>
              <img src={vk} alt='vk' className='md:w-[300px] w-[200px] h-[200px]' />
            </div>
            <div className='partner-box flex justify-center bg-customBlack shadow-md shadow-slate-400'>
              <img src={rhaekc} alt='rhaekc' className='md:w-[300px] w-[200px] h-[200px] md:h-[300px]' />
            </div>
            <div className='partner-box flex justify-center bg-customBlack shadow-md shadow-slate-400'>
              <img src={huawei} alt='huawei' className='md:w-[300px] w-[200px] h-[200px] md:h-[300px]' />
            </div>
          </div>
          <div className='grid grid-cols-2 md:grid-cols-4 gap-4 mx-4'>
            <div className='partner-box flex justify-center bg-customBlack shadow-md shadow-slate-400'>
              <img src={samsung} alt='samsung' className='md:w-[300px] w-[200px] h-[200px] md:h-[300px]' />
            </div>
            <div className='partner-box flex justify-center bg-customBlack shadow-md shadow-slate-400'>
              <img src={mi} alt='mi' className='md:w-[300px] w-[200px] h-[200px] md:h-[300px]' />
            </div>
            <div className='partner-box flex justify-center bg-customBlack shadow-md shadow-slate-400'>
              <img src={vivo} alt='vivo' className='md:w-[300px] w-[200px] md:h-[300px] h-[200px]' />
            </div>
            <div className='partner-box flex justify-center bg-customBlack shadow-md shadow-slate-400'>
              <img src={oppo} alt='oppo' className='md:w-[300px] w-[200px] md:h-[300px] h-[200px]' />
            </div>
          </div>
        </div>
        <hr className='text-white h-1 mt-32' />
        {/* Promotion section */}
        <div className='container mx-auto items-center my-14'>
          <div className='text-white space-y-3'>
            <button className='border-1px bg-gray-700 rounded-md px-2 py-2 font-medium font-serif'> 🛸 Our Technology</button>
            <h2 className='font-medium uppercase text-2xl md:text-4xl'>How we</h2>
            <h1 className='font-bold uppercase text-3xl md:text-5xl'>
              <span className="bg-gradient-to-r from-orange-500 to-orange-800 bg-clip-text text-transparent">Promote</span>
            </h1>
            <div className='rounded-lg border-[1px] bg-gradient-to-r from-orange-800 to-orange-500 bg-opacity-20 md:py-10 py-4 px-4 md:px-10 grid grid-cols-1 md:grid-cols-2 lg:relative'>
              <div>
                <h1 className='text-2xl md:text-4xl font-bold'>Programmatic</h1>
                <div className='md:py-6 py-4'>
                  <ul className='space-y-3 font-medium text-[13px] md:text-[15px] list-disc'>
                    <li>Boost Your App's Growth with an In-App Advertising Platform</li>
                    <li>Access 40+ Ad Exchanges for Maximum Advertising Reach</li>
                    <li>Device Type & Model: Smartphones, Tablets, and More</li>
                    <li>Demographics: Audience Insights and Characteristics</li>
                    <li>Playable and Other Creative Ad Formats</li>
                  </ul>
                </div>
              </div>
              <div className='lg:absolute  top-[-240px] right-[-10px]'>
                <img src={affiliate} alt='' className='md:w-[500px] w-[300px]'/>
              </div>
            </div>
            <div className='grid grid-cols-1 md:grid-cols-2 rounded-lg border-[1px] py-4 px-4 bg-custom-gradient'>
              <div className=''>
                <img src={query} alt='query' className='w-full h-auto' />
              </div>
              <div>
                <h1 className='text-2xl md:text-4xl font-bold'>Over 150 Advertising Networks</h1>
                <div className='md:py-6 py-4'>
                  <ul className='space-y-3 font-medium text-[13px] md:text-[15px] list-disc'>
                    <li>Our leading mobile ad experts</li>
                    <li>Every geographic location</li>
                    <li>Regular updates</li>
                    <li>KPI achievement</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Features section */}
        <div className='container mx-auto items-center my-20'>
          <div className='flex justify-center my-6'>
            <button className='border-1px bg-gray-700 rounded-md px-2 py-2 font-medium font-serif text-white'> 💪 Our Features</button>
          </div>
          <h1 className='text-white font-bold text-3xl md:text-5xl text-center'>Take Your App to the Next Level</h1>
          <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 md:mt-14 mt-6'>
            <div className='text-white p-8 md:p-14 text-center rounded-[100%] shadow-lg bg-gradient-to-r from-orange-500 to-orange-800 mx-4 md:mx-14'>
              <h2 className='text-xl md:text-2xl font-bold mb-2'>Verticals</h2>
              <p>Food delivery, entertainment, finance, e-commerce, dating, games, betting, social, gambling</p>
            </div>
            <div className='bg-custom-gradient text-white px-6 py-14 md:py-20 rounded-lg shadow-lg text-center mx-4'>
              <h2 className='text-xl md:text-2xl font-bold mb-2'>Cooperation Models</h2>
              <p>CPI, CPA, CPM, CPC</p>
            </div>
            <div className='bg-custom-gradient text-white px-6 py-14 md:py-20 rounded-lg shadow-lg text-center mx-4'>
              <h2 className='text-xl md:text-2xl font-bold mb-2'>RT</h2>
              <p>Returning users</p>
            </div>
            <div className='text-white p-8 md:p-14 text-center rounded-[100%] shadow-lg bg-gradient-to-r from-orange-500 to-orange-800 mx-4 md:mx-14'>
              <h2 className='text-xl md:text-2xl font-bold mb-2 '>Targets</h2>
              <p>Geo, SuperGeo, OS version, Device, SocDem</p>
            </div>
            <div className='bg-custom-gradient text-white px-6 py-14 md:py-20 rounded-lg shadow-lg text-center mx-4'>
              <h2 className='text-xl md:text-2xl font-bold mb-2'>UA</h2>
              <p>Attracting new users</p>
            </div>
            <div className='bg-custom-gradient text-white px-6 py-14 md:py-20 rounded-lg shadow-lg text-center mx-4'>
              <h2 className='text-xl md:text-2xl font-bold mb-2'>GEO</h2>
              <p>WW</p>
            </div>
          </div>
        </div>
        {/* Support section */}
        <div className='container mx-auto items-center my-20'>
          <div className='text-center'>
            <div className='flex justify-center my-6'>
              <button className='border-1px bg-gray-700 rounded-md px-2 py-2 font-medium font-serif text-white'> 💼 Our Support</button>
            </div>
            <h1 className="text-3xl uppercase font-medium text-white">TAILOR MADE SUPPORT</h1>
            <h2 className='text-3xl md:text-5xl uppercase bg-gradient-to-r from-orange-500 to-orange-800 bg-clip-text text-transparent font-bold'>SPECIFIC FOR YOU</h2>
          </div>
          <div className='grid grid-cols-1 md:grid-cols-2 md:my-10 my-5 gap-16'>
            <div className='bg-white md:py-10 py-5 px-4 md:px-14 text-center rounded-lg mx-4'>
              <h1 className='uppercase bg-gradient-to-r from-orange-500 to-orange-800 bg-clip-text text-transparent text-2xl md:text-4xl font-bold'>Our team of</h1>
              <h1 className='uppercase bg-gradient-to-r from-orange-500 to-orange-800 bg-clip-text text-transparent text-2xl md:text-4xl font-bold'>Experts</h1>
              <p className='py-6 text-customGray font-semibold'>To collaborate closely with you on your digital strategies, both locally and globally.</p>
              <ul className='list-disc font-semibold text-customGray'>
                <li> More than 40 experts</li>
                <li>+10 certified media traders</li>
                <li>Exclusive partnerships (data, media, DSPs)</li>
              </ul>
            </div>
            <div className='bg-white md:py-10 py-5 px-4 md:px-14 text-center rounded-lg mx-4'>
              <h1 className='uppercase bg-gradient-to-r from-orange-500 to-orange-800 bg-clip-text text-transparent text-2xl md:text-4xl font-bold'>PERSONALIZED</h1>
              <h1 className='uppercase bg-gradient-to-r from-orange-500 to-orange-800 bg-clip-text text-transparent text-2xl md:text-4xl font-bold'>FOLLOW-UP</h1>
              <p className='py-6 text-customGray font-semibold'>To provide you with critical insights and relevant recommendations to accelerate your business</p>
              <ul className='list-disc font-semibold text-customGray'>
                <li> 24/7 dashboard for real-time data</li>
                <li>Reports adapted to your needs</li>
                <li>Strategic meetings to leverage your marketing investments</li>
              </ul>
            </div>
          </div>
          <NavLink to={"/services"}><div className='flex justify-center'>
           <button className="border-[1px] w-full md:w-1/3 border-white text-white bg-transparent px-6 py-2 rounded-lg hover:bg-gradient-to-r from-orange-500 to-orange-800 hover:border-none transition">Our Support</button>
          </div></NavLink>
        </div>
      </div>
    </div>
  );
}

export default Home;
