import React, { useState } from 'react';
import google from '../assets/images/google.png';
import vungle from '../assets/images/vungle.png';
import tiktok from '../assets/images/tiktok.png';
import unity from '../assets/images/unity (1).png';
import taboola from '../assets/images/taboola.png';
import shareit from '../assets/images/shareit.png';
import ironsource from '../assets/images/ironsource.png';
import applovin from '../assets/images/applovin.png';
import chartboost from '../assets/images/chartboost.png';
import liftoff from '../assets/images/liftoff.png';
import lenovo from '../assets/images/lenovo.png';
import xoom from '../assets/images/xoom.png';
import youtube from '../assets/images/YouTube.png';
import facebook from '../assets/images/facebook.png';
import samsung from '../assets/images/samsung (1).png';
import appnext from '../assets/images/appnext.png';
import instagram from '../assets/images/instagram.png';
import bigoads from '../assets/images/bigo_ads.png';



export default function Services() {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        companyName: '',
        geo: ''
    });
    const [activeService, setActiveService] = useState(1); // Track active service

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log(formData); // For demonstration, you can log the form data
        // You can add further logic here, such as sending the form data to a server
    };

    const data = [
        { id: 1, 
        name: "In-App Advertising",
        para:"Advertising within mobile applications provides a powerful marketing tool for companies to directly promote their products or services to users. Effective use of in-app advertising can be a powerful tool for promoting products or services, especially given the growing number of mobile users and their activity within applications.",
        photos :[
            { id: 1, pic: google,},
            { id: 2, pic: vungle },
            { id: 3, pic: tiktok },
            { id: 4, pic: unity },
            { id: 5, pic: taboola },
            { id: 6, pic: shareit },
            { id: 7, pic: ironsource },
            { id: 8, pic: applovin },
            { id: 9, pic: chartboost },
            { id: 10, pic: liftoff },
        ],
        Box : [
            {
                id: 1,
                pic: <i className="fa-solid fa-bullseye text-6xl text-orange-600"></i>,
                name: "Target Audience",
                description: "In-app advertising allows for precise targeting based on users' interests, demographics, and app usage behavior, optimizing costs and enhancing the chances of attracting potential customers.",
            },
            {
                id: 2,
                pic: <i className="fa-solid fa-hand-point-up text-6xl text-orange-600"></i>,
                name: "Interactivity and Attractiveness",
                description: "Advertising formats within applications often provide interactive opportunities, fostering deeper consumer engagement. Videos, banners, and other formats enhance the appeal and memorability of ads.",
            },
            {
                id: 3,
                pic: <i className="fa-solid fa-circle-question text-6xl text-orange-600"></i>,
                name: "Support for Mobile User Experience",
                description: "In-app advertising seamlessly integrates into the user interface of mobile applications, maintaining the natural flow of the user journey. This reduces the likelihood of ads being blocked or ignored, making them a cohesive part of the overall user interaction with the app.",
            },
            {
                id: 4,
                pic: <i className="fa-solid fa-weight-scale text-6xl text-orange-600"></i>,
                name: "Measurement and Analytics",
                description: "Advertisers can utilize analytics tools to assess the effectiveness of their campaigns in real-time, enabling quick adjustments, strategy optimization, and maximization of return on investment.",
            },
            {
                id: 5,
                pic: <i className="fa-solid fa-star text-6xl text-orange-600"></i>,
                name: "Brand Recognition",
                description: "In-app advertising offers the chance to present ads in a context aligned with the user's interests, enhancing brand recognition. This integration makes the brand a natural part of the user's daily interaction with the mobile application.",
            }
        ],
     },
        { id: 2, 
          name: "OEM", 
          para: "In mobile advertising, OEM (Original Equipment Manufacturer) refers to partnering with device manufacturers to market products or services through pre-installed applications and device interfaces.", 
          photos: [
            { id: 1, pic: lenovo },
            { id: 2, pic: xoom },
            { id: 3, pic: samsung },
            { id: 4, pic: appnext },
            { id: 5, pic: ironsource },
            { id: 6, pic: google },
          ],
          Box: [
            {
                id: 1,
                pic: <i class="fa-solid fa-volume-low"></i>,
                name: "Advertising",
                description: "OEM advertising for apps engages users at various stages of the device lifecycle. By targeting users during key moments like onboarding, it allows for precise targeting based on device model, location, and user demographics such as age and gender. Additionally, OEM advertising feels integrated and beneficial, rather than disruptive.",
            },
            {
                id: 2,
                pic: <i class="fa-brands fa-squarespace"></i>,
                name: "Adjustable Placements",
                description: "We optimize campaigns based on the advertiser's conversion rate from installation to the CPA goal. Best practices include choosing the highest-performing creatives and placements within the OEM ecosystem.",
            },
            {
                id: 3,
                pic: <i class="fa-solid fa-pen-nib"></i>,
                name: "Transparency",
                description: "Sure, here's an alternate version of the text Full transparency is essential. Prominent companies like Xiaomi, OPPO, VIVO, and Samsung emphasize brand reputation and user quality. Advertisers can trust that users will open the app and view the advertisement.",
                    
                
            },
            {
                id: 4,
                pic: <i class="fa-solid fa-star"></i>,
                name: "Brand Campaigns",
                description: "OEM brand campaigns are highly effective for reinforcing and maintaining brand image, sharing updates, and facilitating rebranding efforts.",
                    
                
            }
          ]
        },
        { id: 3, 
            name: "Media Buying",
            para:"Mobile media buying enables companies to effectively reach the mobile audience by considering its distinct characteristics and behaviors. These strategies ensure high personalization and relevance in advertising, leading to maximum impact.",
            photos :[
                { id: 1, pic: lenovo ,},
                { id: 2, pic: youtube },
                { id: 3, pic: tiktok },
                { id: 4, pic: unity },
                { id: 5, pic: taboola },
                { id: 6, pic: shareit },
                { id: 7, pic: facebook },
                { id: 8, pic:  bigoads},
                { id: 9, pic:  instagram},
            ],
            Box : [
                {
                    id: 1,
                    pic: <i class="fa-solid fa-layer-group"></i>,
                    name: "Platform Selection for Mobile Devices",
                    description: "Mobile devices provide various platforms such as apps, mobile websites, games, and social networks. Mobile media buying involves choosing the best platforms based on the target audience and campaign objectives.",
                },
                {
                    id: 2,
                    pic: <i class="fa-solid fa-mobile"></i>,
                    name: "Optimization for Mobile Device Formats",
                    description: "Advertising materials are tailored to accommodate the unique characteristics of mobile devices, including screen size, touchscreen functionality, and limited space. Creatives are designed to be visually compelling and optimized for the mobile environment.",
                },
                {
                    id: 3,
                    pic: <i class="fa-solid fa-circle-dot"></i>,
                    name: "Targeting Based on Mobile Data",
                    description: "Mobile media buying leverages mobile user behavior data for precise targeting, including geographic, contextual, and user preference-based targeting strategies.",
                },
                {
                    id: 4,
                    pic: <i className="fa-solid fa-weight-scale text-6xl text-orange-600"></i>,
                    name: "Performance Measurement",
                    description: "Analytics are utilized to monitor the effectiveness of advertising campaigns, evaluating engagement rates, conversions, app installations, and other critical metrics.",
                },
                {
                    id: 5,
                    pic: <i class="fa-solid fa-users-viewfinder"></i>,
                    name: "Optimization for Mobile Traffic",
                    description: "Continuous optimization of mobile traffic is achieved throughout the campaign through machine learning technologies and algorithmic selection",
                }
            ],
         },
         { id: 4, 
            name: "Retargating", 
            para: "Retargeting in mobile advertising is a marketing strategy where advertising is directed at users of mobile devices who have previously shown interest in specific products, services, or content but have not completed a desired action, such as making a purchase or registering.", 
            photos: [
              { id: 1, pic: lenovo },
              { id: 2, pic: xoom },
              { id: 3, pic: samsung },
              { id: 4, pic: appnext },
              { id: 5, pic: ironsource },
              { id: 6, pic: google },
            ],
            Box: [
            //   {
            //       id: 1,
            //       pic: <i class="fa-solid fa-volume-low"></i>,
            //       name: "Advertising",
            //       description: "OEM advertising for apps engages users at various stages of the device lifecycle. By targeting users during key moments like onboarding, it allows for precise targeting based on device model, location, and user demographics such as age and gender. Additionally, OEM advertising feels integrated and beneficial, rather than disruptive.",
            //   },
            //   {
            //       id: 2,
            //       pic: <i class="fa-brands fa-squarespace"></i>,
            //       name: "Adjustable Placements",
            //       description: "We optimize campaigns based on the advertiser's conversion rate from installation to the CPA goal. Best practices include choosing the highest-performing creatives and placements within the OEM ecosystem.",
            //   },
            //   {
            //       id: 3,
            //       pic: <i class="fa-solid fa-pen-nib"></i>,
            //       name: "Transparency",
            //       description: "Sure, here's an alternate version of the text Full transparency is essential. Prominent companies like Xiaomi, OPPO, VIVO, and Samsung emphasize brand reputation and user quality. Advertisers can trust that users will open the app and view the advertisement.",
                      
                  
            //   },
            //   {
            //       id: 4,
            //       pic: <i class="fa-solid fa-star"></i>,
            //       name: "Brand Campaigns",
            //       description: "OEM brand campaigns are highly effective for reinforcing and maintaining brand image, sharing updates, and facilitating rebranding efforts.",
                      
                  
            //   }
            ]
          },
    ];

    return (
        <div className='bg-customGray md:px-4 px-2 py-2'>
            <div className='md:my-10 my-5 md:py-6 py-2'>
                <h1 className='text-white text-4xl sm:text-5xl md:text-[140px] text-center font-bold uppercase mb-2'>
                    Services
                </h1>
                <p className='text-sm md:text-base font-gotham text-center text-white leading-[1.537em]'>
                    Achieving financial results for our clients<br />in the shortest possible time
                </p>
                <div className='flex flex-wrap gap-6 justify-center my-8 py-4'>
                    {data.map((item, index) => (
                        <button
                            key={index}
                            onClick={() => setActiveService(item.id)} // Set active service
                            className={`text-white border-white border-[1px] py-2 md:py-4 px-4 md:px-8 rounded-3xl ${activeService === item.id ? 'bg-gradient-to-r from-orange-500 to-orange-800 border-none' : 'bg-transparent hover:bg-gradient-to-r from-orange-500 to-orange-800 hover:border-none'}`}>
                            {item.name}
                        </button>
                    ))}
                </div>
                {data.filter(info => info.id === activeService).map((info, index) => (
                    <div className='md:mt-28  sm:mx-4 mx-2 md:mx-10 sm:px-4 px-2 md:px-10' key={index}>
                        <h1 className='text-white font-semibold text-4xl md:text-5xl text-center'>{info.name}</h1>
                        <div className='grid grid-cols-1 md:grid-cols-2 my-4 pt-8'>
                            <div className='text-white text-base px-4 md:px-8'>
                                <p>{info.para}</p>
                            </div>
                            <div className='grid grid-cols-3 gap-6 px-4 md:px-8 md:pt-0 pt-6'>
                                {info.photos.map((item, index) => (
                                    <div key={index}>
                                        <img src={item.pic} alt='appimg' className='w-[70px] md:w-[90px]' />
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className='grid grid-cols-1 md:grid-cols-2 gap-6 py-8'>
                            {info.Box.map((item, index) => (
                                <div className='text-white px-4 md:px-10 py-6 md:py-10' key={index}>
                                    <div className='text-6xl text-orange-600'>{item.pic}</div>
                                    <h1 className='font-semibold text-3xl md:text-4xl py-2'>{item.name}</h1>
                                    <p className='text-base'>{item.description}</p>
                                </div>
                            ))}
                        </div>
                    </div>
                ))}
            </div>
            <div className='px-4 md:px-20 grid grid-cols-1 md:grid-cols-2'>
                <div className=''>
                    <div className='text-center'><i className="fa-solid fa-arrow-down text-6xl text-orange-600"></i></div>
                    <h1 className='text-white md:text-5xl text-4xl font-bold px-4 md:leading-[70px]'>Ready to Amplify Your App's Reach?</h1>
                </div>
                <div className='mb-10 pb-10 sm:pt-0 pt-6'>
                    <form onSubmit={handleSubmit} className='bg-customBlack p-4 md:p-8 rounded-xl shadow- shadow-slate-300'>
                        <div className='mb-4 text-white'>
                            <label htmlFor='name' className='block text-white font-bold mb-2'>Name:</label>
                            <input type='text' id='name' name='name' value={formData.name} onChange={handleChange} className='border bg-customGray rounded-md px-4 py-2 w-full' required />
                        </div>
                        <div className='mb-4 text-white'>
                            <label htmlFor='email' className='block text-white font-bold mb-2'>Email:</label>
                            <input type='email' id='email' name='email' value={formData.email} onChange={handleChange} className='border bg-customGray rounded-md px-4 py-2 w-full' required />
                        </div>
                        <div className='mb-4 text-white'>
                            <label htmlFor='companyName' className='block text-white font-bold mb-2'>Company Name:</label>
                            <input type='text' id='companyName' name='companyName' value={formData.companyName} onChange={handleChange} className='border bg-customGray rounded-md px-4 py-2 w-full' required />
                        </div>
                        <div className='mb-4 text-white'>
                            <label htmlFor='geo' className='block text-white font-bold mb-2'>Enter Geo:</label>
                            <input type='text' id='geo' name='geo' value={formData.geo} onChange={handleChange} className='border bg-customGray rounded-md px-4 py-2 w-full' required />
                        </div>
                        <div className='text-center'>
                            <button type='submit' className='bg-gradient-to-r from-orange-500 to-orange-800 text-white px-8 py-3 rounded-md font-bold hover:bg-orange-600 transition duration-300 uppercase'>Submit</button>
                        </div>
                    </form>
                </div>
                
            </div>
        </div>
    );
}
