import React from 'react'

export default function PrivacyPolicy() {
  return (
   <>
    <div className='bg-customGray md:px-4 sm:px-2 px-1 py-2'>
     <div className='text-center text-white uppercase py-4 font-semibold md:text-2xl text-lg'>
        <h1>Privacy & Policy</h1>
     </div>
     <div className='py-4 text-white md:px-4 sm:px-2 px-1'>
        <p className='text-base leading-7'>XonAds is committed to protecting the privacy and security of our users’ personal information. This Privacy Policy outlines how we collect, use, disclose, and protect the personal data we obtain from users in compliance with the General Data Protection Regulation (GDPR) and the California Privacy Rights Act (CPRA).</p>
     </div>
     <div className='py-4 text-white md:px-4 sm:px-2 px-1'>
     <h1 className='font-semibold text-lg'>1. Information We Collect</h1>
     <p className='text-base leading-7'>We collect various types of information from our users, including:</p>
     <ul className='list-disc'>
        <li className='text-base leading-7'>Personal Information: such as name, email address, postal address, phone number, and other identifiers.</li>
        <li className='text-base leading-7'>Usage Data: including information about how you interact with our website or mobile application.</li>
        <li className='text-base leading-7'>Cookies and Tracking Technologies: for analytics, advertising, and improving user experience.</li>
     </ul>
     </div>
     <div className='py-4 text-white md:px-4 sm:px-2 px-1'>
     <h1 className='font-semibold text-lg'>2. How We Use Your Information</h1>
     <p className='text-base leading-7'>We use the information we collect for the following purposes:</p>
     <ul className='list-disc'>
        <li className='text-base leading-7'>To provide and maintain our services.</li>
        <li className='text-base leading-7'>To personalize user experience and improve our products and services.</li>
        <li className='text-base leading-7'>To communicate with users, including responding to inquiries and providing updates.</li>
        <li  className='text-base leading-7'>To analyze usage patterns and trends to enhance our website and marketing strategies.</li>
     </ul>
     </div>
     <div className='py-4 text-white md:px-4 sm:px-2 px-1'>
     <h1 className='font-semibold text-lg'>3. Data Sharing and Disclosure</h1>
     <p className='text-base leading-7'>We may share your personal information with third parties for the following purposes:</p>
     <ul className='list-disc'>
        <li className='text-base leading-7'>Service Providers: to assist us in providing our services and improving user experience.</li>
        <li className='text-base leading-7'>Legal Compliance: when required by law or in response to valid legal requests.</li>
        <li className='text-base leading-7'>Business Transfers: in connection with a merger, acquisition, or sale of assets.</li>
     </ul>
     </div>
     <div className='py-4 text-white md:px-4 sm:px-2 px-1'>
     <h1 className='font-semibold text-lg'>4. Your Rights and Choices</h1>
     <p className='text-base leading-7'>Under the GDPR and CPRA, you have certain rights regarding your personal data, including the right to:</p>
     <ul className='list-disc'>
        <li className='text-base leading-7'>Access: request access to your personal information.</li>
        <li className='text-base leading-7'>Rectification: request correction of inaccurate or incomplete data.</li>
        <li className='text-base leading-7'>Erasure: request deletion of your personal data under certain circumstances.</li>
        <li className='text-base leading-7'>Data Portability: request transfer of your data to another organization.</li>
        <li className='text-base leading-7'>Object: object to the processing of your personal information for certain purposes.</li>
     </ul>
     </div>
     <div className='py-4 text-white md:px-4 sm:px-2 px-1'>
     <h1 className='font-semibold text-lg'>5. Data Security</h1>
     <p className='text-base leading-7'>We take the security of your personal information seriously and implement appropriate technical and organizational measures to protect it from unauthorized access, disclosure, alteration, or destruction.</p>
     </div>
     <div className='py-4 text-white md:px-4 sm:px-2 px-1'>
     <h1 className='font-semibold text-lg'>6. Updates to this Privacy Policy</h1>
     <p className='text-base leading-7'>We may update this Privacy Policy from time to time to reflect changes in our practices or legal requirements. We encourage you to review this page periodically for the latest information.</p>
     </div>
     <div className='py-4 text-white md:px-4 sm:px-2 px-1 pb-10'>
     <h1 className='font-semibold text-lg'>7. Contact Us</h1>
     <p className='text-base leading-7'>If you have any questions or concerns about our Privacy Policy or our data practices, please contact us at support@xonads.com.

By using our website or services, you consent to the collection and use of your information as outlined in this Privacy Policy.</p>
     </div>
     </div>
   </>
  )
}
