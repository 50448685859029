import React from 'react'

export default function AdvertiserTerms() {
  return (
    <>
     <div className='bg-customGray md:px-4 sm:px-2 px-1 py-2'>
     <div className='text-center text-white uppercase py-4 font-semibold md:text-2xl sm:text-xl text-lg leading-9 sm:leading-[0px]'>
        <h1>Advertisers Terms & Conditions</h1>
     </div>
     <div className='py-4 text-white md:px-4 sm:px-2 px-1'>
        <p className='text-base leading-7'>This XonAds Advertiser Terms & Conditions (“Advertiser Agreement”) shall govern the relationship between XonAds Pte Ltd, 5001 Beach Road #08-11, Golden Mile Complex, Singapore – 199588 (“XonAds Pte Ltd”) and the advertiser (“Advertiser”), whereby Advertiser may obtain access to the XonAds Advertising Network (“XonAds Advertising Network”) of registered third party affiliates and publishers (“Publishers”), and related technology and software (“XonAds Advertising Network Ad Server”), to market customized advertisements and links provided by Advertiser and/or XonAds (“Ads” as further defined below). The XonAds Advertising Network, as well as the services provided by XonAds in connection therewith (“Services”), are further described in the Insertion Order (“IO”) which refers to these Advertiser Terms & Conditions (the IO, together with this Advertiser Agreement, the “Agreement”). The terms of the IO shall supersede all contrary terms set forth in this Advertiser Agreement, unless expressly set forth to the contrary. In any instance where Advertiser is an agency entering into the Agreement on behalf of a client, any reference to “Advertiser” shall refer jointly to Advertiser as well as the applicable underlying client.</p>
     </div>
     <div className='py-4 text-white md:px-4 sm:px-2 px-1'>
        <h1 className='uppercase md:text-[20px] text-[16px] font-bold'>1. XonAds Advertising Network/Services</h1>
        <p className='text-base leading-7'>Advertiser agrees to accept and pay for, and XonAds agrees to provide, the Services identified and set forth in the Agreement. In connection with the Services, XonAds shall undertake marketing campaigns with Advertiser (each an “Ad Campaign”) whereby XonAds will distribute Advertiser’s proprietary advertising materials including, without limitation, banners, buttons, text-links, clicks, co-registrations, pop-ups, pop-unders, e-mail, graphic files and similar online media (collectively, “Advertiser Ads”) and/or, where applicable, XonAds Advertising Network Ads (as defined below) through the XonAds Advertising Network either:</p>
        <ul className='list-disc'>
            <li className='text-base leading-7 py-4'>by Publishers via email-based marketing, search engine marketing, website-based marketing, and/or other online and mobile marketing means. In connection with such Ad Campaigns, Advertiser shall pay XonAds commissions depending on the number of valid clicks (“CPC”) or valid, compensable conversion events such as leads, downloads, installs, users, paying users, or any other defined action or result generated on behalf of Advertiser as set forth in the subject IO (collectively, “CPA/CPL” or “Actions”; including CPC). The applicable Actions, the fees due to XonAds for each Action, and other applicable terms and conditions of the Ad Campaigns entered into hereunder shall be specified in each IO. XonAds shall not be held liable or responsible for any actions or omissions of its Publishers.</li>
            <li className='text-base leading-7 py-4'>on Publisher websites via the XonAds Advertising Network Ad Server for impressions-based (“CPM”), click-based (“CPC”), and action-based (“CPA/CPL”) Ad Campaigns as defined below:</li>
        </ul>
     </div>
     <div className='py-4 text-white md:px-4 sm:px-2 px-1'>
        <h1 className='uppercase md:text-[20px] text-[16px] font-bold'>2. Account</h1>
        <p className='text-base leading-7'>Upon the execution of the Agreement, XonAds will register Advertiser on the XonAds Advertising Network website and create a unique, password-protected account (“Account”). XonAds will manage this Account on behalf of Advertiser. In case Advertiser receives the login data to this Account, Advertiser will be responsible for safeguarding and maintaining the confidentiality of its Account and associated password.</p>
        <p className='text-base leading-7 py-4'>Advertiser shall remain fully and solely responsible for any and all actions taken under Advertiser’s Account, whether performed by Advertiser or authorized by them. The same applies to any actions taken under Advertiser’s Account if the login data to this Account was given to any third party by Advertiser. Advertiser must immediately notify XonAds of any unauthorized use of Advertiser’s Account. Advertiser is responsible for keeping the Account information provided to XonAds current, complete, and accurate. Advertiser acknowledges and agrees that XonAds will have no responsibility or liability, directly or indirectly, for failure to deliver notices as a result of inaccurate Account information.</p>
     </div>
     <div className='py-4 text-white md:px-4 sm:px-2 px-1 '>
        <h1 className='uppercase md:text-[20px] text-[16px] font-bold'>3. Ads</h1>
        <p className='text-base leading-7'>Advertiser shall develop all aspects of the Advertiser Ads, except where the parties agree that XonAds shall assist in the development of Ads. The parties understand and agree that Advertiser is the sole owner of any and all intellectual property rights associated with any Advertiser Ads, other than those portions that XonAds or any Publisher prepares on Advertiser’s behalf (such portions hereinafter referred to as the “XonAds Advertising Network Ads” and together with the Advertiser Ads, the “Ads”). The parties understand and agree that XonAds or the respective Publisher is the sole owner of any and all intellectual property rights associated with the XonAds Advertising Network Ads, other than Advertiser’s trademarks, logos, copyrights, and other pre-existing Advertiser intellectual property incorporated in the XonAds Advertising Network Ads. Under no circumstances shall XonAds or the respective Publisher be authorized to use the Ads other than in connection with Advertiser’s Ad Campaigns as set forth in the IO(s). XonAds reserves the right, in its reasonable discretion and without liability, to change any of its Ad Guidelines at any time and to reject, omit, exclude, or terminate any Ad for any reason at any time, with subsequent notice to the Advertiser, whether or not such Ad was previously acknowledged, accepted, or published by XonAds. Such reasons for rejection, omission, or exclusion of Ads include, but are not limited to, where XonAds deems, in its reasonable discretion, that the Ads, including the applicable products and/or services promoted by such Ads (“Advertiser Products”), and any website linked to from such Ads, are in violation of any applicable law, rule, regulation, or other judicial or administrative order, or where the content thereof may tend to bring disparagement, ridicule, or scorn upon XonAds or any of its Publishers.</p>
     </div>
     <div className='py-4 text-white md:px-4 sm:px-2 px-1 '>
        <h1 className='uppercase md:text-[20px] text-[16px] font-bold'>4. Placement</h1>
        <p className='text-base leading-7'>The positioning, placement, frequency, and other editorial decisions related to Ads shall be made by XonAds and/or its Publishers, as applicable, in their respective sole discretion. The applicable IO may set forth the particular place(s) where Ads may appear and/or be distributed. Advertiser agrees that in cases where no points of placement or distributions are set forth in the applicable IO, or where “Run of Advertising Network” or a similar designation is specified in the applicable IO, the Ads may appear at any point of placement and/or distribution that XonAds and/or its Publishers may determine, in their respective sole discretion.</p>
     </div>
     <div className='py-4 text-white md:px-4 sm:px-2 px-1'>
        <h1 className='uppercase md:text-[20px] text-[16px] font-bold'>5. Ad Codes, Conversion Tracking and Tracking</h1>
        <p className='text-base leading-7'>Unless otherwise stated in writing by XonAds, each Ad or link used by XonAds in connection with a Campaign must include, in unaltered form, the special transaction tracking computer code or tracking link provided by XonAds (“Ad Codes”). Advertiser will not knowingly modify, circumvent, impair, disable, or otherwise interfere with any Ad Codes and/or other technology and/or methodology required or made available by XonAds to be used in connection with any and all Ads in order to track actions such as installs, leads, or other conversion events.</p>
        <p className='text-base leading-7 py-2'>If Advertiser is working with several marketing partners, they must ensure that for all campaigns run through XonAds the "last click wins principle" applies. This means the conversion event will be attributed to the marketing partner that generated the last click of the respective user before the conversion event.</p>
        <p  className='text-base leading-7 py-2'>In case "server-to-server" tracking is employed to track the conversion events ("Actions") defined in the IO, Advertiser has to ensure that all defined Actions are accurately tracked and timely reported to XonAds’s system including the correct unique click ID used by XonAds in the tracking URL. In case technical problems or outages caused by either of the parties’ systems lead to a non-restorable loss of conversion data, one of the following methods should be used to determine the correct number and attribution of conversion events (applied in the order of their listing): alternate the text and also replace the text global media by XonAds</p>
        <ol className='order-1'>
            <li className='text-base leading-7 py-2'>1. "Manual" matching of MAC addresses, IDFAs, or any other unique identifiers that can be retrieved ex-post by the Publisher and Advertiser to determine attribution and the number of conversion events, or alternate text and replace the word Globale Media with XonAds.</li>
            <li className='text-base leading-7 py-2'>2. approximation based on historical conversion rate data (click-to-action) from XonAds system in the following preferential order:</li>
            <ul className='list-disc'>
                <li className='text-base leading-7 py-2'>if available from the same campaign,</li>
                <li className='text-base leading-7 py-2'>or from the most comparable campaign for which historical data is available in XonAds system. “Comparable” means that the campaign should be closely comparable with regards to the defined conversion event (“action”), product and platform, chosen countries, advertising methods used and Publishers.</li>
            </ul>
        </ol>
     </div>
     <div className='py-4 text-white md:px-4 sm:px-2 px-1 '>
        <h1 className='uppercase md:text-[20px] text-[16px] font-bold'>6. Technical and Commercial Limitations; Changes; Availability</h1>
        <p className='text-base leading-7 py-2'>Unless otherwise provided for in the Agreement, XonAds provides the XonAds Advertising Network through which it renders its services on an “AS IS” and “AS AVAILABLE” basis.</p>
        <p className='text-base leading-7 py-2'>XonAds offers the Advertiser use of its XonAds Advertising Network over the Internet subject to technical and commercial limitations as defined below.</p>
        <p className='text-base leading-7 py-2'>XonAds may modify the XonAds Advertising Network without prior notice. Therefore, the Advertiser is granted the right to use only the then-current version. The Advertiser may reduce or cease its use of the XonAds Advertising Network if it is modified.</p>
        <p className='text-base leading-7 py-2'>XonAds reserves the right to cease operation of the XonAds Advertising Network at any time, without giving reasons or prior notice. Any balance owed to the Advertiser will be paid out. Any other claims are excluded, unless otherwise provided for in the Agreement.</p>
        <p className='text-base leading-7 py-2'>XonAds undertakes to ensure an availability of the XonAds Advertising Network of 95% (ninety-five percent) as a yearly average. Periods during which the XonAds Advertising Network is not available due to technical or other problems outside XonAds’s control (such as force majeure or third-party fault) and periods during which routine maintenance works are carried out, are excluded from this. XonAds may restrict access to the XonAds Advertising Network if required for network security, maintenance of network integrity, and the prevention of severe malfunction of the network, the software, or stored data. The Advertiser’s rights in case of intent or gross negligence remain unaffected.</p>
     </div> 
     <div className='py-4 text-white md:px-4 sm:px-2 px-1 '>
        <h1 className='uppercase md:text-[20px] text-[16px] font-bold'>7. Payments</h1>
        <p className='text-base leading-7 py-2'>    The rates for Actions shall be set forth in the applicable IO(s). XonAds will invoice Advertiser once monthly. Unless otherwise set forth in the applicable IO, payment will be due to XonAds within fifteen (15) days of the date appearing on each invoice.</p>
        <p className='text-base leading-7 py-2'>In connection with CPA/CPL-based Ad Campaigns, Advertiser will pay XonAds for all Actions generated; provided, however, that Advertiser shall have no obligation to pay for any Lead/CPA/CPL-based Action that:</p>
        <p className='text-base leading-7 py-2'>1.it rejects within two (3) days of its receipt thereof; and</p>
        <p className='text-base leading-7 py-2'>2.both parties determine is not a Valid Action (as defined below). A “Valid Action” means that the action</p>
        <ul className='list-disc'>
            <li className='text-base leading-7 py-2'>was not performed by a computer generated user, such as a robot, spider, computer script or other automated, artificial or fraudulent method designed to appear like an individual, real live person;</li>
            <li>in the case of CPA/CPL-based Campaign, is a valid sale that is not fraudulent, cancelled, charged back or otherwise nullified.</li>
        </ul>
        <p className='text-base leading-7 py-2'>XonAds retains the right to charge additional fees and interest for the delay of payments.</p>
     </div>
     <div className='py-4 text-white md:px-4 sm:px-2 px-1 '>
        <h1 className='uppercase md:text-[20px] text-[16px] font-bold'>8. Term/Termination</h1>
        <p className='text-base leading-7 py-2'>The Agreement shall continue for the term set forth in any underlying IO, provided that either party may terminate the Agreement and/or any IO at any time, upon three (3) business days’ prior written notice. The right to an extraordinary termination of this Agreement for good cause remains unaffected.</p>
        <p className='text-base leading-7 py-2'>Upon termination or expiration of the Agreement for any reason:</p>
        <ul className='list-disc'>
            <li className='text-base leading-7 py-2'>Advertiser will pay XonAds all amounts then due and owing as of the termination date within fifteen (15) days as set forth in Section 7 hereinabove;</li>
            <li className='text-base leading-7 py-2'>Advertiser will pay XonAds all amounts then due and owing as of the termination date within fifteen (15) days as set forth in Section 7 hereinabove;</li>
        </ul>
        <p className='text-base leading-7 py-2'>Any and all licenses and rights granted to either party in connection with the Agreement shall immediately cease and terminate; and</p>
    </div>
    <div className='py-4 text-white md:px-4 sm:px-2 px-1 '>
        <h1 className='uppercase text-[20px] font-bold'>9. Warranty/Limitation of Liability</h1>
        <ul className='list-disc'>
            <li className='text-base leading-7 py-2'>XonAds is not responsible for damages, unless they are caused intentionally or by gross negligence.</li>
            <li className='text-base leading-7 py-2'>Globale Media is not responsible for damages, unless they are caused intentionally or by gross negligence.</li>
            <li className='text-base leading-7 py-2'>The aforementioned limitations of liability do not apply to the liability for personal injury of life, body, and health or in case the damage is the result of a guarantee. The aforementioned limitation of liability also applies to the personal liability of staff, employees, assistants, vicarious agents, contributors, representatives, organs, shareholders of Globale Media and their members.</li>
        </ul>
    </div>
    <div className='py-4 text-white md:px-4 sm:px-2 px-1 '>
        <h1 className='uppercase md:text-[20px] text-[16px] font-bold'>10. Representation and Warranties</h1>
        <p className='text-base leading-7 py-2'>Advertiser represents and warrants that:</p>
        <ul className='list-disc'>
            <li className='text-base leading-7 py-2'>it has the power and authority to enter into and perform its obligations under the Agreement;</li>
            <li className='text-base leading-7 py-2'>at all times, the Ads (and their transmission), the Advertiser Products, any Advertiser website linked to from the Ads and Advertiser itself will comply with all applicable foreign, federal, state or local laws, rules, regulations and ordinances;</li>
            <li className='text-base leading-7 py-2'>It owns and/or has any and all rights to permit the use of the Advertiser Ads and, where approved, XonAds Advertising Network Ads, by XonAds, the Publishers, as contemplated by the Agreement;</li>
            <li className='text-base leading-7 py-2'>At all times, the Ads (and their transmission), the Advertiser Products, any Advertiser website linked to from the Ads, and Advertiser itself will not violate any applicable rights of any third party, including, but not limited to, infringement or misappropriation of any copyright, patent, trademark, trade secret, or other proprietary, property, or other intellectual property right;</li>
            <li className='text-base leading-7 py-2'>Prior to loading any computer program onto an individual’s computer, including, without limitation, programs commonly referred to as adware and/or spyware, but excluding cookies (provided that cookies are disclosed in Advertiser’s privacy policy and end-users are instructed on how to disable such cookies), Advertiser shall provide clear and conspicuous notice to, and shall obtain the express consent of, such individual to install such computer program;</li>
        </ul>
    </div>
    <div className='py-4 text-white md:px-4 sm:px-2 px-1 '>
        <h1 className='uppercase md:text-[20px] text-[16px] font-bold'>11. Indemnification</h1>
        <p className='text-base leading-7 py-2'>Each party agrees to indemnify and hold the other party harmless from and against any losses, costs, liabilities, and expenses, including reasonable attorneys’ fees, arising out of any third-party claims resulting from the breach of obligations or warranties made by such party in the Agreement. The indemnifying party has the right, at the indemnifying party’s expense, to assume the exclusive defense and control of any matter for which the indemnifying party is required to indemnify the indemnified party. The indemnified party agrees to cooperate with the indemnifying party’s defense of such claims.</p>
        
    </div>
    <div className='py-4 text-white md:px-4 sm:px-2 px-1 '>
        <h1 className='uppercase md:text-[20px] text-[16px] font-bold'>12. Claims Based on Defects</h1>
        <p className='text-base leading-7 py-2'>XonAds grants the Advertiser access to the XonAds Advertising Network in the then-current version only. The Advertiser cannot claim that a given state or functional range is maintained or achieved. The Advertiser acknowledges that the XonAds Advertising Network, like any other software, can never be completely free of bugs. Therefore, the XonAds Advertising Network can only be considered defective if its usability is affected severely and for a significant period of time.</p>
        <p className='text-base leading-7 py-2'>XonAds grants the Advertiser access to the XonAds Advertising Network in the then-current version only. The Advertiser cannot claim that a given state or functional range is maintained or achieved. The Advertiser acknowledges that the XonAds Advertising Network, like any other software, can never be completely free of bugs. Therefore, the XonAds Advertising Network can only be considered defective if its usability is severely affected for a significant period of time.</p>
    </div>
    <div className='py-4 text-white md:px-4 sm:px-2 px-1'>
        <h1 className='uppercase md:text-[20px] text-[16px] font-bold'>13. Confidentiality</h1>
        <p className='text-base leading-7 py-2'>No party shall disclose any Confidential Information of the other party during the term of this Agreement and for a period of five years following the termination or expiration of this Agreement. For purposes of the Agreement, “Confidential Information” shall mean all data and information, of a confidential nature or otherwise, disclosed during the term of the Agreement by one party (“Disclosing Party”) to the other party (“Receiving Party”), as well as information that the Receiving Party knows or should know that the Disclosing Party regards as confidential including, but not limited to:</p>
        <ul className='list-disc'>
            <li className='text-base leading-7 py-2'>a party’s business plans, strategies, know how, marketing plans, suppliers, sources of materials, finances, business relationships, personally identifiable end-user information, pricing, technology, employees, trade secrets and other non-public or proprietary information whether written, oral, recorded on tapes or in any other media or format;</li>
            <li className='text-base leading-7 py-2'>the material terms of the Agreement and/or any associated IO(s);</li>
            <li className='text-base leading-7 py-2'>Any information marked or designated by the Disclosing Party as confidential. The Receiving Party agrees to hold all Confidential Information in trust and confidence and, except as may be authorized by the Disclosing Party in writing, shall not use such Confidential Information for any purpose other than as expressly set forth in the Agreement or disclose any Confidential Information to any person, company, or entity, except to those of its employees and professional advisers:</li>
            <p  className='text-base leading-7 py-2'>1. who need to know such information in order for the Receiving Party to perform its obligations hereunder; and</p>
            <p className='text-base leading-7 py-2'>2. who have entered into a confidentiality agreement with the Receiving Party with terms at least as restrictive as those set forth herein.</p>
            <p className='text-base leading-7 py-2'>Confidential information shall not include any information that the Receiving Party can verify with substantial proof that:</p>
            <p className='text-base leading-7 py-2'>1. is generally available to or known to the public through no wrongful act of the receiving party;</p>
            <p className='text-base leading-7 py-2'>2. was disclosed to the Receiving Party by a third party legally in possession of such Confidential Information and under no obligation of confidentiality to the Disclosing Party. The Receiving Party agrees that monetary damages for breach of confidentiality may not be adequate and that the disclosing party shall be further entitled to injunctive relief, without the requirement to post bond.</p>

        </ul>
    </div>
    <div className='py-4 text-white md:px-4 sm:px-2 px-1 pb-10 '>
        <h1 className='uppercase md:text-[20px] text-[16px] font-bold'>14. Non-Circumvention</h1>
        <p className='text-base leading-7 py-2'>Advertiser recognizes that XonAds has proprietary relationships with the Publishers. Advertiser agrees not to circumvent XonAds’s relationship with such Publishers, or to otherwise solicit, purchase, contract for, or obtain services similar to the Services performed by XonAds hereunder from any Publishers that are known, or should reasonably be known, by Advertiser to have such a relationship with XonAds, during the term of the Agreement and for three (3) months following termination or expiration of the Agreement. Notwithstanding the foregoing, to the extent that Advertiser can show that any such Publishers already provided such services to Advertiser prior to the date of the first IO executed by the parties, then Advertiser shall not be prohibited from continuing such a relationship.</p>
    </div>
    </div>
    </>
  )
}
